import img1 from '../../src/images/data/vip-1.png';
import img2 from '../../src/images/data/vip-2.jpg';
import img3 from '../../src/images/data/vip-3-2.jpg';



import mainSlide from '../../src/images/data/vip-image-app.jpg';
// import ios from '../../src/images/data/vip/skyvip-ios.png';
// import and from '../../src/images/data/vip/skyvip-android.png';
import ico from '../../src/images/data/VIP2.jpg';

import ios from '../../src/images/data/app/my-sky/mysky-ios.png';
import and from '../../src/images/data/app/my-sky/mysky-android.png';



import logo from '../../src/images/data/logo-colored.jpg';

const q = {
    data : {
        wp: {
            vip: {
                dataHeader : {
                    home:true, 
                    logo:logo,
                },
                options : [
                    {
                        whiteText:true,
                        type:null,
                        id: 'q-apps',
                        text : '<h1>Sky VIP - The treatment you deserve.</h1><p>From Idris to Isabella, everyone gets the VIP treatment.<br/><br/>You can get tickets to the biggest games, access to places you only ever dreamed of, free trials on new apps and much more. And the best part is, the longer you are with us, the better it gets.<br/><br/>So go on, check out all the great value you get from Sky VIP just for being a customer on the My Sky App today</p>',
                        image: {
                            mediaItemUrl:img1
                        },
                    },
                    {   
                        whiteText:true,
                        type:null,
                        id: 'q-ent',
                        text : '<h1>Treat yourself to a Sky Store movie, on us</h1><p>As a Sky VIP, you can access a huge range of exclusive rewards and experiences.<br/><br/>To start you off, we’re treating you to a Sky Store movie.<br/><br/>Visit the VIP section of the My Sky app, for full details and terms.</p>',
                        image: {
                            mediaItemUrl:img2
                        },
                    },
                    {
                        whiteText:false,
                        type:'app',
                        id: 'q-app',
                        ico : ico,
                        text : '<h1>My Sky</h1><p>Brilliantly simple and personalised to you, My Sky makes managing your account, paying your bill and getting help easier than ever before. Get instant access to the information you need, when you need it. With My Sky, you can join our free loyalty programme, Sky VIP. It’s also the quickest and easiest way to securely manage your Sky products and services, pay your bill and get help if you need it.</p>',
                        qr : [
                            {
                                img : ios,
                                code : 'ios',
                                link : '',
                                label : 'iOS'
                            },
                            {
                                img : and,
                                code : 'and',
                                link : '',
                                label : 'Android'
                            },
                            
                        ],
                        image: {
                            mediaItemUrl:mainSlide
                        },
                    },
                ]
            }
        },

    }
}

export default q;